@import url(https://fonts.googleapis.com/css?family=Inknut+Antiqua:400,700);
@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.06e2cdfd.eot);
  src: url(/static/media/icomoon.06e2cdfd.eot#iefix) format("embedded-opentype"), url(/static/media/icomoon.828085f8.ttf) format("truetype"), url(/static/media/icomoon.85bb9006.woff) format("woff"), url(/static/media/icomoon.cddc8d50.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
}
/* line 12, ../sass/styles.scss */
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

.icon-envelop:before {
  content: "\E945";
}
.icon-menu:before {
  content: "\E9BD";
}
.icon-instagram:before {
  content: "\EA92";
}
.icon-discord:before {
  content: "\E900";
}

/* line 81, ../sass/styles.scss */
body {
  background: #000000;
  margin: 0;
  padding: 0;
  font-family: "Inknut Antiqua", Arial, helvetica, sans-serif;
  color: #333333;
  font-size: 14px;
}
@media only screen and (min-width: 930px) {
  /* line 81, ../sass/styles.scss */
  body {
    font-size: 16px;
  }
}

/* line 93, ../sass/styles.scss */
h3 {
  font-size: 16px;
}

/* line 97, ../sass/styles.scss */
a {
  text-decoration: underline;
  color: #000000;
}
/* line 100, ../sass/styles.scss */
a:hover {
  text-decoration: none;
}

/* line 105, ../sass/styles.scss */
input, textarea {
  display: block;
}

/* line 109, ../sass/styles.scss */
textarea {
  resize: vertical;
  min-width: 300px;
}

/* line 114, ../sass/styles.scss */
blockquote {
  line-height: 24px;
}

/* line 118, ../sass/styles.scss */
button {
  border-radius: 10px;
  background: #000000;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  border: none;
  padding: 10px 15px 10px 15px;
  margin: 10px 0 10px 0;
  display: inline-block;
}
/* line 129, ../sass/styles.scss */
button:hover, button:active, button:focus {
  text-decoration: underline;
}

/* line 135, ../sass/styles.scss */
ul li, ol li {
  line-height: 24px;
  margin: 15px 0 15px 0;
}

/* line 141, ../sass/styles.scss */
img {
  max-width: 100%;
}
/* line 143, ../sass/styles.scss */
img.inline {
  padding: 10px;
  width: 300px;
}
/* line 146, ../sass/styles.scss */
img.inline.left {
  float: left;
}
/* line 147, ../sass/styles.scss */
img.inline.right {
  float: right;
}

/* line 151, ../sass/styles.scss */
.container {
  width: auto;
  margin: 0 auto;
  max-width: 1000px;
}

/* line 157, ../sass/styles.scss */
header {
  background: #000000;
  color: #ffffff;
  text-align: center;
  margin: 0 auto 0;
  display: block;
  max-width: 800px;
  padding: 0 10px 0 10px;
  line-height: 20px;
}
@media only screen and (min-width: 930px) {
  /* line 157, ../sass/styles.scss */
  header {
    padding: 0 100px 0 100px;
    line-height: inherit;
  }
}
/* line 170, ../sass/styles.scss */
header img {
  margin: 0 auto 0 auto;
}
/* line 173, ../sass/styles.scss */
header h1.hide {
  visibility: hidden;
  font-size: 1px;
  margin: 0;
  line-height: 0;
}
/* line 179, ../sass/styles.scss */
header span {
  font-style: italic;
  font-size: 12px;
}
@media only screen and (min-width: 930px) {
  /* line 179, ../sass/styles.scss */
  header span {
    font-size: 16px;
  }
}

/* line 188, ../sass/styles.scss */
nav, .main-container, footer {
  width: 100%;
}

/* line 192, ../sass/styles.scss */
.main-container {
  background: #ffffff;
  display: table;
  min-height: 400px;
}
/* line 196, ../sass/styles.scss */
.main-container .left-border, .main-container .right-border, .main-container main {
  display: table-cell;
}
@media only screen and (min-width: 930px) {
  /* line 199, ../sass/styles.scss */
  .main-container .left-border {
    width: 4px;
    background: #ffffff;
    border-left: 30px solid #000000;
    border-right: 30px solid #000000;
  }
}
@media only screen and (min-width: 930px) {
  /* line 204, ../sass/styles.scss */
  .main-container .right-border {
    width: 4px;
    background: #ffffff;
    border-left: 30px solid #000000;
    border-right: 30px solid #000000;
  }
}
/* line 210, ../sass/styles.scss */
.main-container main > section {
  padding: 80px 15px 15px 15px;
}
/* line 212, ../sass/styles.scss */
.main-container main > section.about-page {
  padding-top: 0;
}
/* line 214, ../sass/styles.scss */
.main-container main > section.about-page h2 {
  margin: 40px 0 10px 0;
}
@media only screen and (min-width: 930px) {
  /* line 210, ../sass/styles.scss */
  .main-container main > section {
    padding: 100px 40px 40px 40px;
  }
}
/* line 225, ../sass/styles.scss */
.main-container main img.top_banner {
  padding-top: 40px;
}
/* line 228, ../sass/styles.scss */
.main-container main h2 {
  padding-top: 10px;
  margin-top: 10px;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 930px) {
  /* line 228, ../sass/styles.scss */
  .main-container main h2 {
    margin-bottom: 40px;
  }
}
/* line 238, ../sass/styles.scss */
.main-container main h3 {
  font-size: 20px;
  line-height: 24px;
}
/* line 242, ../sass/styles.scss */
.main-container main p {
  line-height: 22px;
  margin: 20px 0 20px 0;
}
/* line 245, ../sass/styles.scss */
.main-container main p.form-error {
  margin-top: 0;
  font-weight: bold;
  font-size: 14px;
  font-style: italic;
}
@media only screen and (min-width: 930px) {
  /* line 242, ../sass/styles.scss */
  .main-container main p {
    line-height: 24px;
  }
}

/* line 258, ../sass/styles.scss */
nav {
  display: table;
  position: relative;
}
/* line 261, ../sass/styles.scss */
nav .left-border, nav .right-border, nav .links {
  display: table-cell;
}
/* line 264, ../sass/styles.scss */
nav .links {
  vertical-align: top;
  height: 50px;
  padding-top: 31px;
  padding-left: 30px;
  padding-right: 30px;
}
@media only screen and (min-width: 930px) {
  /* line 264, ../sass/styles.scss */
  nav .links {
    padding-left: 60px;
    padding-right: 60px;
    text-align: center;
  }
}
/* line 275, ../sass/styles.scss */
nav .links .links-wrapper {
  display: block;
  overflow: hidden;
  color: #ffffff;
  padding: 3px 10px 3px 10px;
  height: 40px;
}
@media only screen and (min-width: 930px) {
  /* line 275, ../sass/styles.scss */
  nav .links .links-wrapper {
    height: auto;
    border-left: none;
    border-right: none;
    border-top: 4px solid #ffffff;
  }
}
/* line 287, ../sass/styles.scss */
nav .links .links-wrapper .menu-button {
  display: block;
  cursor: pointer;
}
@media only screen and (min-width: 930px) {
  /* line 292, ../sass/styles.scss */
  nav .links .links-wrapper .menu-button {
    display: none;
  }
}
/* line 296, ../sass/styles.scss */
nav .links .links-wrapper.active {
  height: auto;
  overflow: visible;
  background: #000000;
  position: absolute;
  z-index: 100;
}
/* line 302, ../sass/styles.scss */
nav .links .links-wrapper.active a {
  display: block;
}
/* line 306, ../sass/styles.scss */
nav .links .links-wrapper a {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  color: #ffffff;
  text-decoration: none;
}
/* line 311, ../sass/styles.scss */
nav .links .links-wrapper a:hover, nav .links .links-wrapper a.active {
  text-decoration: underline;
}

/* line 319, ../sass/styles.scss */
footer {
  color: #ffffff;
  text-align: center;
  padding-top: 30px;
  position: relative;
}
/* line 324, ../sass/styles.scss */
footer p {
  margin: 0 0 4px 0;
  line-height: 18px;
}
/* line 328, ../sass/styles.scss */
footer a {
  color: #ffffff;
}
/* line 332, ../sass/styles.scss */
footer .social-links a {
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
}
/* line 338, ../sass/styles.scss */
footer .social-links a [class^="icon-"], footer .social-links a [class*=" icon-"] {
  font-size: 22px;
  line-height: 24px;
}

/* line 346, ../sass/styles.scss */
.flourish {
  display: none;
  position: absolute;
  width: 90px;
  height: 90px;
}
@media only screen and (min-width: 930px) {
  /* line 346, ../sass/styles.scss */
  .flourish {
    display: block;
  }
}
/* line 355, ../sass/styles.scss */
.flourish.top-left {
  width: 190px;
  height: 190px;
  top: 12px;
  left: 10px;
  z-index: 100;
}
/* line 363, ../sass/styles.scss */
.flourish.top-right {
  -webkit-transform: -webkit-rotate(0deg);
          transform: -webkit-rotate(0deg);
  transform: -ms-rotate(0deg);
  transform: rotate(0deg);
  top: 12px;
  right: 13px;
}
/* line 368, ../sass/styles.scss */
.flourish.bottom-left {
  -webkit-transform: -webkit-rotate(180deg);
          transform: -webkit-rotate(180deg);
  transform: -ms-rotate(180deg);
  transform: rotate(180deg);
  bottom: 30px;
  left: 10px;
}
/* line 373, ../sass/styles.scss */
.flourish.bottom-right {
  -webkit-transform: -webkit-rotate(90deg);
          transform: -webkit-rotate(90deg);
  transform: -ms-rotate(90deg);
  transform: rotate(90deg);
  bottom: 30px;
  right: 10px;
}

/* line 380, ../sass/styles.scss */
.cta-text {
  position: absolute;
  z-index: 110;
  margin: 0;
  text-align: center;
  background: #000000;
  border: 2px solid #ffffff;
  border-radius: 500px;
  padding: 10px;
  top: 20px;
  right: 0;
  font-size: 20px;
  line-height: 30px;
  width: 120px;
  height: 120px;
}
@media only screen and (min-width: 930px) {
  /* line 380, ../sass/styles.scss */
  .cta-text {
    font-size: 24px;
    padding: 0;
    background: none;
    left: 51px;
    top: 60px;
    right: auto;
    width: 115px;
    height: 100px;
    border: none;
  }
}
/* line 408, ../sass/styles.scss */
.cta-text a {
  color: #ffffff;
  text-decoration: none;
  margin-top: 15px;
  display: block;
}
@media only screen and (min-width: 930px) {
  /* line 408, ../sass/styles.scss */
  .cta-text a {
    margin: 0;
    display: inline;
  }
}
/* line 417, ../sass/styles.scss */
.cta-text a:hover, .cta-text a:active, .cta-text a:focus {
  text-decoration: underline;
}

/* line 423, ../sass/styles.scss */
.two-col {
  vertical-align: top;
  display: block;
}
@media only screen and (min-width: 930px) {
  /* line 423, ../sass/styles.scss */
  .two-col {
    width: 45%;
    display: inline-block;
    margin: 0 2.5% 0 2.5%;
  }
}

/* line 433, ../sass/styles.scss */
.clear {
  clear: both;
}

/* line 437, ../sass/styles.scss */
.none {
  display: none;
}

/* line 440, ../sass/styles.scss */
.block {
  display: block;
}

